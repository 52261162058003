import { useState, useEffect } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import DeleteModal from '../../components/common/SimpleModal/delete'
import FilterContainer from '../../components/Filter'
import { getCustomerList, deleteCustomer, clearDefaultCustomerData, setToast, } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import constants from '../../constants'
import { posthogCaptureEvent } from '../../utils'

import { Pagination, } from 'antd'
import CustomerTableMobile from '../../components/customers/mobile'
import CustomerTableDesktop from '../../components/customers/desktop'
import type { RootState } from '../../redux'
import { DeleteModalState } from '../../types/common'

const Customers: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()
  const { t } = useTranslation()
  const { customerActionLoading, pageMetaData, success } = useAppSelector((state: RootState) => state.customer)
  const { isMobile } = useAppSelector((state) => state.common)

  const [deleteModal, setDeteteModal] = useState<DeleteModalState>({
    type: '',
    visible: false,
    id: '',
    name: '',
    title: '',
    desc: ''
  })
  const [filterValues, setFilterValues] = useState<string>('')

  useEffect(() => {
    dispatch(getCustomerList({ currentPage: 1, size: constants.PAGE_SIZE, name: '' }))
  }, [dispatch])

  useEffect(() => {
    if (deleteModal.id && success.type === 'Success') {
      dispatch(setToast({
        show: true,
        content: t('common.info.deletedCustomerSuccess')
      }))
      dispatch(getCustomerList({ currentPage: 1, size: constants.PAGE_SIZE, name: filterValues }))
      handleCloseDeleteModal()
    }
  }, [success])

  const handleNavigateToNewCustomer = () => {
    dispatch(clearDefaultCustomerData())
    navigate(config.pages.create_customer)
  }
  const handleViewClick = (value: string) => {
    posthogCaptureEvent('view_screen', { type: 'customer' })
    navigate(`${config.pages.view_customer}/${value}`)
  }
  const handleEditClick = (value: string) => {
    posthogCaptureEvent('edit_screen', { type: 'customer' })
    navigate(`${config.pages.edit_customer}/${value}`)
  }

  const handleDeleteClick = (value: string, name: string) => {
    posthogCaptureEvent('delete_screen', { type: 'customer' })
    setDeteteModal({
      type: constants.DELETE,
      visible: true,
      id: value,
      name,
      title: t(`deleteModal.title.customer`),
      desc: t('customers.deleteModalBody')
    })
  }

  const handleDeleteConfirmModal = (id: string) => {
    posthogCaptureEvent('delete_action_confirmation', { type: 'customer', id })
    dispatch(deleteCustomer(id))
  }

  const handleCloseDeleteModal = () => {
    posthogCaptureEvent('delete_action_cancel', { type: 'customer' })
    setDeteteModal({ type: '', visible: false, id: '', name: '', title: '', desc: '' })
  }

  const handleFilterValues = async (name: string) => {
    posthogCaptureEvent('applied_filters', { type: 'customer', value: name || 'RESET' })
    setFilterValues(name)
    dispatch(getCustomerList({ currentPage: 1, size: constants.PAGE_SIZE, name }))
  }

  const handleChangePagination = (pageNo: number) => {
    posthogCaptureEvent('change_pagination', { type: 'customer', pageNo })
    dispatch(getCustomerList({ currentPage: pageNo, size: constants.PAGE_SIZE, name: filterValues }))
  }

  const handleCopyCustomerToken = (token: string) => {
    posthogCaptureEvent('copy_token', { type: 'customer', token })
    const tokenLink = `https://www.peoplechit.in/customer/${token}`
    const textArea = document.createElement('textarea')
    textArea.value = tokenLink
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    dispatch(setToast({
      show: true,
      content: t('common.info.copiedTokenSuccess')
    }))
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <FilterContainer
        t={t}
        showFilter={!!filterValues.length || pageMetaData.totalRecords > 10}
        filterType={constants.filterType.CUSTOMER}
        showCustomerOnly={true}
        showSearch={true}
        actionName={t('customers.createCTA')}
        handleCreateAction={handleNavigateToNewCustomer}
        handleFilterValues={handleFilterValues}
      />
      {isMobile ? (
        <CustomerTableMobile
          handleViewClick={handleViewClick}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleCopyCustomerToken={handleCopyCustomerToken}
        />
      ) : (
        <CustomerTableDesktop
          handleViewClick={handleViewClick}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
          handleCopyCustomerToken={handleCopyCustomerToken}
        />
      )}
      {pageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={pageMetaData.totalRecords}
          current={pageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
      <DeleteModal
        visibleModal={deleteModal.visible}
        data={deleteModal}
        loading={customerActionLoading}
        confirmModal={handleDeleteConfirmModal}
        closeModal={handleCloseDeleteModal}
      />
    </div>
  )
}

export default Customers
