import React, { useState, useEffect } from 'react'
import {
  getAllChitGroupTransactionList,
  getAutocompleteChitGroupList,
  createChitGroupTransaction,
  updateChitGroupTransaction,
  clearDefaultChitGroupTransactionData,
  createLogSnag,
  setToast,
  clearAutoCompleteChitGroupData
} from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getBEFormat, disabledAfterTodayDate, getTodayDate, posthogCaptureEvent } from '../../utils'
import constants from '../../constants'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Button, Modal, Select, DatePicker, InputNumber, Checkbox, Spin } from 'antd'
import { ModalLoader } from '../../components/common/PageLoader'
import type { RootState } from '../../redux'

interface ModalProps {
  isView: boolean
  visibleModal: boolean
  closeModal: any
  currentPage: number
  filters: string
}

const CreateTransactionModal: React.FC<ModalProps> = ({ isView, visibleModal, closeModal, currentPage, filters }) => {
  const [chitGroup, setChitGroup] = useState<any>()
  const [installment, setInstallment] = useState<any>()
  const [transactionDate, setTransactionDate] = useState<any>('')
  const [customerDueDate, setCustomerDueDate] = useState<any>('')
  const [settlementDueDate, setSettlementDueDate] = useState<any>('')
  const [dividendAmount, setDividendAmount] = useState<any>()
  const [customerPayableAmount, setCustomerPayableAmount] = useState<any>('')
  const [discountAmount, setDiscountAmount] = useState<any>()
  const [settlementAmount, setSettlementAmount] = useState<any>('')
  const [triedUserCount, setTriedUserCount] = useState<number>(1)
  const [createAnotherChitTransaction, setCreateAnotherChitTransaction] = useState<boolean>(false)

  const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
  let timeout: ReturnType<typeof setTimeout> | null

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { chitAutoCompleteGroupLoading, filteredChitGroupList } = useAppSelector((state: RootState) => state.chitGroup)
  const { isLoading, chitTransactionActionLoading, defaultChitGroupTransaction, success, error } = useAppSelector((state: RootState) => state.chitGroupTransaction)

  const { PAGE_SIZE, format } = constants

  useEffect(() => {
    setShowErrMsg(false)
  }, [visibleModal])

  useEffect(() => {
    handleSetChitGroupTransactionValues()
  }, [defaultChitGroupTransaction])

  useEffect(() => {
    if (success.type === 'Success') {
      dispatch(getAllChitGroupTransactionList({ currentPage, size: PAGE_SIZE, filters }))
      createAnotherChitTransaction ? handleResetValues() : closeModal()
    }
  }, [success])

  useEffect(() => {
    const getMonths: any = filteredChitGroupList.find(({ ID }: any) => ID === chitGroup)
    if (getMonths) {
      const lastCompletedInstallment: number = getMonths.maxInstallmentNumber ? getMonths.maxInstallmentNumber + 1 : 1
      setInstallment(lastCompletedInstallment)
    }
  }, [chitGroup])

  const handleSetChitGroupTransactionValues = () => {
    const data = defaultChitGroupTransaction
    if (Object.keys(data).length) {
      setChitGroup({
        value: data.chitgroupID,
        label: data.chitName
      })
      setInstallment(data.installmentNumber)
      setTransactionDate(dayjs(data.transactionDate, format.DATE_FORMAT))
      setCustomerDueDate(dayjs(data.customerDueDate, format.DATE_FORMAT))
      setSettlementDueDate(dayjs(data.settlementDueDate, format.DATE_FORMAT))
      setDividendAmount(data?.dividendAmount)
      setCustomerPayableAmount(data.customerPayableAmount)
      setDiscountAmount(data.discountAmount)
      setSettlementAmount(data.settlementAmount)
      setTriedUserCount(data.triedUserCount || 1)
    } else {
      handleResetValues(true)
    }
  }

  const handleDisableUpdateButton = () => {
    const data = defaultChitGroupTransaction
    return (
      triedUserCount !== (data.triedUserCount || 1) ||
      getBEFormat(transactionDate) !== data.transactionDate ||
      parseInt(discountAmount) !== parseInt(data.discountAmount) ||
      parseInt(settlementAmount) !== parseInt(data.settlementAmount) ||
      parseInt(dividendAmount) !== parseInt(data.dividendAmount) ||
      parseInt(customerPayableAmount) !== parseInt(data.customerPayableAmount) ||
      getBEFormat(settlementDueDate) !== data.settlementDueDate ||
      getBEFormat(customerDueDate) !== data.customerDueDate
    )
  }

  const handleResetValues = (isCreateAnotherChitTransaction = false) => {
    setChitGroup('')
    setInstallment('')
    setTransactionDate(getTodayDate())
    setCustomerDueDate(getTodayDate())
    setSettlementDueDate(getTodayDate())
    setDividendAmount('')
    setCustomerPayableAmount('')
    setDiscountAmount('')
    setSettlementAmount('')
    setTriedUserCount(1)
    setShowErrMsg(false)
    !isCreateAnotherChitTransaction && setCreateAnotherChitTransaction(false)
  }

  const fetchChitGroupList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteChitGroupList(`name=${keyword}&filters=status eq 'ACTIVE'`))
      }, 300)
    } else {
      return []
    }
  }

  const getChitGroupOptions = () => {
    return filteredChitGroupList.map((custom: { chitName: string; ID: string }) => ({ label: custom.chitName, value: custom.ID }))
  }

  const checkDisableSubmit = () => {
    return (
      chitGroup === '' ||
      installment === '' ||
      dividendAmount === '' ||
      !customerPayableAmount ||
      discountAmount === '' ||
      !settlementAmount ||
      transactionDate === '' ||
      settlementDueDate === '' ||
      customerDueDate === ''
    )
  }

  const handleCreateTransaction = async () => {
    if (checkDisableSubmit()) {
      setShowErrMsg(true)
    } else {
      const payload: any = {
        chitgroupID: chitGroup?.value || chitGroup,
        transactionDate: getBEFormat(transactionDate),
        customerDueDate: getBEFormat(customerDueDate),
        dividendAmount: dividendAmount?.toString(),
        customerPayableAmount: customerPayableAmount?.toString(),
        discountAmount: discountAmount?.toString(),
        installmentNumber: installment,
        settlementAmount: settlementAmount?.toString(),
        settlementDueDate: getBEFormat(settlementDueDate),
        settlementTransactionId: defaultChitGroupTransaction.settlementTransactionId,
        collectionTransactionId: defaultChitGroupTransaction.collectionTransactionId,
        triedUserCount
      }
      if (defaultChitGroupTransaction.settlementTransactionId) {
        const res: any = await dispatch(updateChitGroupTransaction(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(setToast({
            show: true,
            content: t('common.info.updatedChitTransactionSuccess')
          }))
          dispatch(clearDefaultChitGroupTransactionData())
          dispatch(clearAutoCompleteChitGroupData())
          posthogCaptureEvent('update_action', { type: 'chit_transaction' }) 
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      } else {
        delete payload.settlementTransactionId
        delete payload.collectionTransactionId
        const logSnagPayload = {
          channel: 'transactions',
          event: 'New Chit Tansaction Created',
          description: JSON.stringify(payload)
        }
        const res: any = await dispatch(createChitGroupTransaction(payload))
        if (res?.payload?.response.status === 200) {
          dispatch(createLogSnag(logSnagPayload))
          dispatch(setToast({
            show: true,
            content: t('common.info.createdChitTransactionSuccess'),
          }))
          dispatch(clearDefaultChitGroupTransactionData())
          dispatch(clearAutoCompleteChitGroupData())
          posthogCaptureEvent('create_action', { type: 'chit_transaction' })
        } else {
          dispatch(setToast({
            show: true,
            type: 'error',
            content: t('errMsg.SYSTEM_DOWN')
          }))
        }
      }
    }
  }

  const handleNavigateToChitGroupTransactions = () => {
    dispatch(clearDefaultChitGroupTransactionData())
    dispatch(clearAutoCompleteChitGroupData())
    closeModal()
  }

  const modalStyle = {
    body: {
      padding: '10px 0 20px 0'
    }
  }

  return (
    <Modal
      open={visibleModal}
      title={`${isView ? '' : defaultChitGroupTransaction.settlementTransactionId ? t('common.edit') : t('common.create')} ${t('createChitGroupTransaction.title')}`}
      onOk={handleCreateTransaction}
      onCancel={handleNavigateToChitGroupTransactions}
      maskClosable={false}
      style={{ top: isMobile ? 20 : undefined }}
      styles={modalStyle}
      width={1000}
      footer={
        isView
          ? [
              <Button size={elementSize} key="back" onClick={handleNavigateToChitGroupTransactions}>
                {isView ? t('common.close') : t('common.cancel')}
              </Button>
            ]
          : [
              <div className="modal-footer" key="createNewOne">
                <div className="input-container m-0 align-items-start">
                  <div>
                    <Checkbox checked={createAnotherChitTransaction} onChange={(e) => setCreateAnotherChitTransaction(e.target.checked)}>
                      {t('common.createOneMoreTransaction')}
                    </Checkbox>
                  </div>
                </div>
                <div key="action-btn's" className="modal-footer-action">
                  <Button size={elementSize} key="back" disabled={chitTransactionActionLoading} onClick={handleNavigateToChitGroupTransactions}>
                    {isView ? t('common.close') : t('common.cancel')}
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    size={elementSize}
                    loading={chitTransactionActionLoading}
                    onClick={handleCreateTransaction}
                    disabled={defaultChitGroupTransaction.settlementTransactionId ? !handleDisableUpdateButton() : false}
                  >
                    {defaultChitGroupTransaction.settlementTransactionId ? t('common.update') : t('common.create')}
                  </Button>
                </div>
              </div>
            ]
      }
    >
      {isLoading || chitTransactionActionLoading ? (
        <ModalLoader />
      ) : (
        <div className="modal-split">
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.chitDate')}
                <span className="input-mandatory">*</span>
              </label>
              <DatePicker
                showToday
                disabledDate={disabledAfterTodayDate}
                disabled={isView}
                allowClear={false}
                status={showErrMsg && transactionDate === '' ? 'error' : ''}
                value={transactionDate}
                format={format.DATE_FORMAT}
                onChange={(date) => setTransactionDate(date)}
                placeholder={t('createChitGroupTransaction.placeholder.chitDate')}
                inputReadOnly={isView}
              />
              {showErrMsg && transactionDate === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.chitName')}
                <span className="input-mandatory">*</span>
              </label>
              <Select
                disabled={isView || defaultChitGroupTransaction.settlementTransactionId}
                value={chitGroup || null}
                showSearch
                filterOption={false}
                onSearch={fetchChitGroupList}
                placeholder={t('createCustomerTransaction.placeholder.chitName')}
                onChange={(option: any) => setChitGroup(option)}
                notFoundContent={chitAutoCompleteGroupLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getChitGroupOptions()}
                status={showErrMsg && !(chitGroup || chitGroup.value) ? 'error' : ''}
                className="select-width"
              />
              {showErrMsg && !(chitGroup || chitGroup.value) && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.installment')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                style={{ width: '100%' }}
                readOnly={true}
                value={installment || null}
                placeholder={t('createChitGroupTransaction.placeholder.installment')}
                status={showErrMsg && !installment ? 'error' : ''}
              />
              {showErrMsg && !installment && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.customerPayableAmount')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={showErrMsg && !customerPayableAmount ? 'error' : ''}
                value={customerPayableAmount}
                onChange={(value: any) => setCustomerPayableAmount(value)}
                readOnly={isView}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('createChitGroupTransaction.placeholder.customerPayableAmount')}
              />
              {showErrMsg && !customerPayableAmount && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.dividend')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={showErrMsg && dividendAmount === '' ? 'error' : ''}
                value={dividendAmount}
                onChange={(value: any) => setDividendAmount(value !== null ? value : '')}
                readOnly={isView}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('createChitGroupTransaction.placeholder.dividend')}
              />
              {showErrMsg && dividendAmount === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
          </div>
          <div className="w-100">
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.customerDueDate')}
                <span className="input-mandatory">*</span>
              </label>
              <DatePicker
                showToday
                disabled={isView}
                allowClear={false}
                status={showErrMsg && customerDueDate === '' ? 'error' : ''}
                value={customerDueDate}
                format={format.DATE_FORMAT}
                onChange={(date) => setCustomerDueDate(date)}
                placeholder={t('createChitGroupTransaction.placeholder.customerDueDate')}
                inputReadOnly={isView}
              />
              {showErrMsg && customerDueDate === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.settlementAmount')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={showErrMsg && !settlementAmount ? 'error' : ''}
                value={settlementAmount}
                onChange={(value: any) => setSettlementAmount(value)}
                readOnly={isView}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                placeholder={t('createChitGroupTransaction.placeholder.settlementAmount')}
              />
              {showErrMsg && !settlementAmount && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.discount')}
                <span className="input-mandatory">*</span>
              </label>
              <InputNumber
                controls={false}
                addonBefore="₹"
                status={showErrMsg && discountAmount === '' ? 'error' : ''}
                value={discountAmount}
                onChange={(value: any) => setDiscountAmount(value !== null ? value : '')}
                readOnly={isView}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength={constants.length.CURRENCY}
                style={{ width: '100%' }}
                formatter={(value) => `${value}`.replace(/(?<=\d)(?=(\d\d)+\d$)/g, ',')}
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                placeholder={t('createChitGroupTransaction.placeholder.discount')}
              />
              {showErrMsg && discountAmount === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">
                {t('createChitGroupTransaction.settlementDueDate')}
                <span className="input-mandatory">*</span>
              </label>
              <DatePicker
                showToday
                disabled={isView}
                allowClear={false}
                status={showErrMsg && settlementDueDate === '' ? 'error' : ''}
                value={settlementDueDate}
                format={format.DATE_FORMAT}
                onChange={(date) => setSettlementDueDate(date)}
                placeholder={t('createChitGroupTransaction.placeholder.settlementDueDate')}
                inputReadOnly={isView}
              />
              {showErrMsg && settlementDueDate === '' && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
            </div>
            <div className="input-container">
              <label className="input-label">{t('createChitGroupTransaction.triedUserCount')}</label>
              <InputNumber
                value={triedUserCount}
                onChange={(value: any) => setTriedUserCount(value !== null ? value : '')}
                readOnly={isView}
                pattern="[0-9]*"
                inputMode="numeric"
                style={{ width: '100%' }}
                placeholder={t('createChitGroupTransaction.placeholder.triedUserCount')}
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default CreateTransactionModal
