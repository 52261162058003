import { useState } from 'react'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Input, InputNumber, Button } from 'antd'
import { userResetPassword } from '../../redux'
import config from '../../config'
import constants from '../../constants'
import configurableData from '../../config/configurable.json'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { emailValidation, phoneNoValidation, allowOnlyNumbers, posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

const Enquiry: React.FC = () => {
  const [name, setName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [position, setPosition] = useState<string>('')
  const [mailId, setMailId] = useState<string>('')
  const [phoneNo, setPhoneNo] = useState<string>('')

  const [showErrMsg, setShowErrMsg] = useState<boolean>(false)
  const [emailErr, setEmailErr] = useState<boolean>(false)
  const [phoneNumberErr, setPhoneNumberErr] = useState<boolean>(false)

  const navigate: NavigateFunction = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { isLoading, error } = useAppSelector((state: RootState) => state.auth)

  const handleSubmitEnquiry = async () => {
    if (name.length > 3 && companyName.length > 3 && position.length > 3 && emailValidation(mailId) && phoneNoValidation(phoneNo)) {
      const payload = { name, companyName, position, mailId, phoneNo }
      const res: any = await dispatch(userResetPassword(payload))
      if (res?.payload?.response.status === 200) {
        posthogCaptureEvent('submit_enquiry', { type: 'enquiry', payload })
      }
    } else {
      handleValidateFields()
    }
  }

  const handleValidateFields = () => {
    if (name.length < 3 || companyName.length < 3 || position.length < 3 || !mailId.length || !phoneNo.length) {
      setShowErrMsg(true)
    }
    setEmailErr(!emailValidation(mailId))
    setPhoneNumberErr(!phoneNoValidation(phoneNo))
  }

  const handleMobileNumber = (value: any) => {
    if (allowOnlyNumbers(value)) {
      setPhoneNo(value)
      setPhoneNumberErr(false)
    }
  }

  const handleNavigateLandingPage = (): void => {
    navigate(config.pages.landingPage)
  }

  return (
    <div className="forgot-password">
      <div>
        <span className="c-pointer" onClick={handleNavigateLandingPage}>
          {configurableData.fullName}
        </span>
      </div>
      <div className="enquiry-content">
        <h2 style={{ textAlign: 'center', fontSize: '30px' }}>{t('enquiry.title')}</h2>
        <div className="input-container">
          <label className="input-label">
            {t('enquiry.name')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={showErrMsg && name.length < 3 ? 'error' : ''}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleValidateFields}
            placeholder={t('enquiry.placeholder.name')}
          />
          {showErrMsg && name.length < 3 && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">
            {t('enquiry.companyName')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={showErrMsg && companyName.length < 3 ? 'error' : ''}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            onBlur={handleValidateFields}
            placeholder={t('enquiry.placeholder.companyName')}
          />
          {showErrMsg && companyName.length < 3 && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>

        <div className="input-container">
          <label className="input-label">
            {t('enquiry.position')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={showErrMsg && position.length < 3 ? 'error' : ''}
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            onBlur={handleValidateFields}
            placeholder={t('enquiry.placeholder.position')}
          />
          {showErrMsg && position.length < 3 && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">
            {t('enquiry.emailId')}
            <span className="input-mandatory">*</span>
          </label>
          <Input
            allowClear={true}
            status={emailErr ? 'error' : ''}
            value={mailId}
            onChange={(e) => setMailId(e.target.value)}
            onBlur={handleValidateFields}
            placeholder={t('enquiry.placeholder.emailId')}
          />
          {emailErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <div className="input-container">
          <label className="input-label">
            {t('enquiry.phoneNo')}
            <span className="input-mandatory">*</span>
          </label>
          <InputNumber
            controls={false}
            status={phoneNumberErr ? 'error' : ''}
            value={phoneNo}
            maxLength={constants.length.PHONE_NUMBER}
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
            onChange={handleMobileNumber}
            onBlur={handleValidateFields}
            style={{ width: '100%' }}
            placeholder={t('enquiry.placeholder.phoneNo')}
          />
          {phoneNumberErr && <p className="input-err-msg">{t('errMsg.mustFilled')}</p>}
        </div>
        <Button type="primary" style={{ margin: '20px 0 0 0' }} size="large" loading={isLoading} onClick={handleSubmitEnquiry}>
          {t('enquiry.submit')}
        </Button>
        <p style={{ color: '#666', margin: '15px 0 30px 0' }}>{t('enquiry.info')}</p>
      </div>
      <div className="p-6 font-light text-left">{configurableData.shortName} © 2024</div>
    </div>
  )
}

export default Enquiry
