import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { clearAnalyticsData, getAnalytics, getDashboardPaymentGraph } from '../../redux'
import constants from '../../constants'
import FilterContainer from '../../components/Filter'
import OverAllData from './overallData'
import PaymentMethodChartData from '../../components/analytics/PaymentMethodChart'
import CollectionDetailsByAmount from '../../components/dashboard/CollectionDetails/amount'
import CollectionDetailsByCount from '../../components/dashboard/CollectionDetails/count'
import { posthogCaptureEvent } from '../../utils'
import type { RootState } from '../../redux'

const Analytics: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [filterValues, setFilterValues] = useState<any>({})

  const { isMobile } = useAppSelector((state) => state.common)
  const { isLoading, analyticsData, paymentTransactionGraphData } = useAppSelector((state: RootState) => state.analytics)

  useEffect(() => {
    dispatch(clearAnalyticsData())
  }, [dispatch])

  const handleFilterValues = (filters: any) => {
    if (filters === '') {
      setFilterValues('')
      dispatch(clearAnalyticsData())
    } else {
      setFilterValues(filters)
      posthogCaptureEvent('applied_filters', { type: 'analytics', value: filters || 'RESET' })
      dispatch(getAnalytics(filters))
      !filters.customerID && dispatch(getDashboardPaymentGraph(filters.chitgroupID))
    }
  }

  const formatGraphAmountData = () => {
    if (paymentTransactionGraphData.amount?.length) {
      const arr: any = []
      paymentTransactionGraphData.amount.forEach(({ period, amount, unpaidAmount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: parseInt(amount),
          type: 'Received'
        })
        arr.push({
          label: shortHandPeriod,
          value: parseInt(unpaidAmount),
          type: 'Not Received'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.amount
  }

  const formatGraphCountData = () => {
    if (paymentTransactionGraphData.count?.length) {
      const arr: any = []
      paymentTransactionGraphData.count.forEach(({ period, count, unpaidCount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: count || 0,
          type: 'Paid'
        })
        arr.push({
          label: shortHandPeriod,
          value: unpaidCount || 0,
          type: 'Unpaid'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.count
  }
  
  return (
    <div style={{ marginTop: '20px' }}>
      <FilterContainer showFilter={true} t={t} filterType={constants.filterType.ANALYTICS} chitGroupPriority={true} handleFilterValues={handleFilterValues} />
      {isLoading ? (
        <Spin className="page-loading" style={{ height: '40vh' }} />
      ) : (
        <div>
          {filterValues?.chitgroupID || filterValues?.customerID || analyticsData?.paymentMethodSplit ? 
              (analyticsData?.paymentMethodSplit ? 
              <>
                <OverAllData t={t} isMobile={isMobile} data={analyticsData} />
                {!filterValues?.customerID && formatGraphAmountData()?.length && formatGraphCountData()?.length && <div className="dashboard-report__container">
                  <div className="dashboard-report">
                    <CollectionDetailsByAmount t={t} data={formatGraphAmountData()} />
                  </div>
                  <div className="dashboard-report">
                    <CollectionDetailsByCount t={t} data={formatGraphCountData()} />
                  </div>
                </div>}
                <div className="dashboard-report__container pt-0">
                  <div className="dashboard-report">
                    <PaymentMethodChartData t={t} data={analyticsData.paymentMethodSplit} />
                  </div>
                </div>
              </> 
              : <p className="m-0 text-center text-gray">{t('common.noTransactions')}</p>)
            : <p className="info">{t('analytics.filterInfo')}</p>
          }
        </div>)}
    </div>
  )
}

export default Analytics
