import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CreateChannelModal from './create'
import { getChannelList, getRefreshToken, setUserChanneId, getAutocompleteChannelList, getDefaultChannel, clearDefaultUserData, clearChannelSetSuccessData, setToast } from '../../redux'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { AiOutlinePlus } from 'react-icons/ai'
import constants from '../../constants'

import { MdOutlineBorderColor } from 'react-icons/md'
import { Space, Table, Tag, Button, Pagination, Select, Spin, } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import type { RootState } from '../../redux'

interface DataType {
  ID: number
  name: string
  channelOwnerFirstName: string
  channelOwnerLastName: string
  email: string
  phoneNumber: number
  status: string
}

const Channels: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [visibleUserModal, setVisibleUserModal] = useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [channel, setChannel] = useState<string>('')

  const { userData } = useAppSelector(((state: RootState) => state.auth))
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { channelSetSuccess } = useAppSelector((state: RootState) => state.user)
  const { isLoading, channelActionLoading, channelList, channelAutoCompleteLoading, filteredChannelList, pageMetaData } = useAppSelector((state: RootState) => state.channel)
  let timeout: ReturnType<typeof setTimeout> | null

  useEffect(() => {
    dispatch(getChannelList({ currentPage: 1, size: constants.PAGE_SIZE }))
  }, [dispatch])

  useEffect(() => {
    if (channelSetSuccess.type === 'Success') {
      dispatch(getRefreshToken({}))
      dispatch(clearChannelSetSuccessData())
      dispatch(setToast({
        show: true,
        content: t('common.info.channelSetSuccess')
      }))
    }
  }, [channelSetSuccess])

  const handleChangePagination = (pageNo: number) => {
    setCurrentPage(pageNo)
    dispatch(getChannelList({ currentPage: pageNo, size: constants.PAGE_SIZE }))
  }

  const fetchChannelList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteChannelList(keyword))
      }, 300)
    } else {
      return []
    }
  }

  const getChannelOptions = () => {
    const channelsList: any[] = filteredChannelList.map((custom: { name: string; channelId: string }) => ({
      label: `${custom.name}`,
      value: custom.channelId
    }))
    channelsList.push({
      label: 'All',
      value: 'ALL'
    })
    return channelsList
  }

  const columns: ColumnsType<DataType> = [
    {
      title: t('channels.tableHeaders.sNo'),
      dataIndex: 'index',
      render: (value, item, index) => <>{(pageMetaData.currentPage - 1) * 10 + index + 1}</>
    },
    {
      title: t('channels.tableHeaders.channelName'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: t('channels.tableHeaders.ownerName'),
      dataIndex: 'channelOwnerFirstName',
      key: 'channelOwnerFirstName',
      render: (value, data) => (
        <>
          {value} {data?.channelOwnerLastName}
        </>
      )
    },
    {
      title: t('channels.tableHeaders.emailId'),
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: t('channels.tableHeaders.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber'
    },
    {
      title: t('channels.tableHeaders.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={status !== 'ACTIVE' ? '' : 'green'}>{status !== 'ACTIVE' ? t('common.inActive') : t('common.active')}</Tag>
    },
    {
      title: t('channels.tableHeaders.action'),
      key: 'action',
      render: (_, record) => (
        <Space>
          <MdOutlineBorderColor onClick={() => handleEditClick(record.ID)} className="c-pointer" />
        </Space>
      )
    }
  ]

  const handleCreateUser = () => {
    dispatch(clearDefaultUserData())
    setVisibleUserModal(true)
    setIsView(false)
  }

  const handleViewClick = (channelId: any) => {
    setIsView(true)
    dispatch(getDefaultChannel({ channelId }))
    setVisibleUserModal(true)
  }

  const handleEditClick = (channelId: any) => {
    setIsView(false)
    dispatch(getDefaultChannel({ channelId }))
    setVisibleUserModal(true)
  }

  const handleSetActiveChannel = () => {
    dispatch(setUserChanneId({ userId: userData.userId, channelId: channel }))
  }

  return (
    <div>
      <div className="d-flex justify-between align-end" style={{ margin: '20px 0', gap: '20px' }}>
        <div className="d-flex align-end w-100" style={{ gap: '10px' }}>
          <div className="select-container m-0">
            <label className="input-label">{t('common.channelName')}</label>
            <Select
              value={channel || null}
              allowClear={true}
              showSearch
              filterOption={false}
              onSearch={fetchChannelList}
              placeholder={t('common.placeholder.selectChannel')}
              onChange={(option: any) => setChannel(option)}
              notFoundContent={channelAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
              options={getChannelOptions()}
              className="select-width"
            />
          </div>
          <Button size={elementSize} type="primary" onClick={handleSetActiveChannel}>
            {t('channels.setCTA')}
          </Button>
        </div>
        <Button size={elementSize} type="primary" onClick={handleCreateUser}>
          {isMobile ? <AiOutlinePlus /> : t('channels.createCTA')}
        </Button>
      </div>
      <Table
        size="middle"
        rowKey={(record: { ID: number }) => record.ID}
        loading={isLoading || channelActionLoading}
        columns={columns as any}
        dataSource={channelList}
        pagination={false}
        scroll={{ x: 1300 }}
        onRow={(record) => {
          return {
            onDoubleClick: () => handleViewClick(record.ID)
          }
        }}
      />
      {pageMetaData && (
        <Pagination
          className="d-flex justify-end align-center"
          style={{ margin: '20px 0' }}
          onChange={handleChangePagination}
          total={pageMetaData.totalRecords}
          current={pageMetaData.currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          hideOnSinglePage={true}
          showSizeChanger={false}
          showQuickJumper={false}
          simple={isMobile}
        />
      )}
      {visibleUserModal && (
        <CreateChannelModal
          isView={isView}
          visibleModal={visibleUserModal}
          closeModal={() => {
            setVisibleUserModal(false)
          }}
          currentPage={currentPage}
        />
      )}
    </div>
  )
}

export default Channels
