import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, NavigateFunction } from 'react-router-dom'
import { MdDelete } from 'react-icons/md'
import { Select, Spin, } from 'antd'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getAutocompleteCustomerList, setRemovedCustomers, setToast } from '../../redux'
import config from '../../config'
import type { RootState } from '../../redux'

interface ChitCustomerProps {
  viewChitGroup: boolean
  isChitActive: boolean
  selectedCustomers: any
  setSelectedCustomers: any
  selectedCustomersErr: string
  setSelectedCustomersErr: any
}

const ChitCustomer: React.FC<ChitCustomerProps> = ({ viewChitGroup, isChitActive, selectedCustomers, setSelectedCustomers, selectedCustomersErr, setSelectedCustomersErr, }) => {
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  let timeout: ReturnType<typeof setTimeout> | null

  const { removedCustomers } = useAppSelector((state: RootState) => state.chitGroup)
  const { customerAutoCompleteLoading, filteredCustomerList } = useAppSelector((state: RootState) => state.customer)

  const [customer, setCustomer] = useState<string>()

  const fetchCustomerList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteCustomerList(keyword))
      }, 300)
    } else {
      return []
    }
  }

  const getCustomerOptions = () => {
    return filteredCustomerList.map((custom: { firstName: string; lastName: string; customerId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.customerId
    }))
  }

  const handleNavigateToCustomer = (id: number) => {
    navigate(`${config.pages.view_customer}/${id}`)
  }

  const handleAddCustomers = (_value: any, option: any) => {
    const isExistingCustomer = selectedCustomers.findIndex(({ value }: any) => value === option.value)
    if (isExistingCustomer !== -1) {
      dispatch(setToast({
        show: true,
        type: 'error',
        content: t('common.info.customerAlreadyAvailable')
      }))
    } else {
      setSelectedCustomers([option, ...selectedCustomers])
      setSelectedCustomersErr('')
    }
    setCustomer(t('common.placeholder.selectCustomer'))
  }

  const handleRemoveUser = (customerID: any) => {
    const filterCustomer = selectedCustomers.filter(({ value }: any) => value === customerID)[0]
    if (filterCustomer.ID) {
      const newList = [
        ...removedCustomers,
        {
          customerID: filterCustomer.value,
          chitgroupID: filterCustomer.chitgroupID,
          ID: filterCustomer.ID,
          isActive: false
        }
      ]
      dispatch(setRemovedCustomers(newList))
      dispatch(setToast({
        show: true,
        type: 'warning',
        content: t('common.info.removedCustomer')
      }))
    }

    const updatedList = selectedCustomers.filter(({ value }: any) => value !== customerID)
    setSelectedCustomers(updatedList)
  }

  return (
    <div className="create-page-section">
      {!(viewChitGroup || isChitActive) && (
        <div className="input-container">
          <label className="input-label">{t('createChitGroup.selectCustomers')}</label>
          <Select
            showSearch
            allowClear={true}
            value={customer || null}
            placeholder={t('common.placeholder.selectCustomer')}
            filterOption={false}
            onSearch={fetchCustomerList}
            onChange={handleAddCustomers}
            notFoundContent={customerAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
            options={getCustomerOptions()}
            className="w-100"
          />
        </div>
      )}

      <div className="input-container mb-0">
        <div className="flex justify-between pb-1">
          <p className="input-label">
            {t('createChitGroup.selectedCustomers')}: <span className="font-medium">{selectedCustomers.length}</span>
          </p>
        </div>
        <div className={`border lg:h-96 overflow-scroll rounded-md p-3 ${selectedCustomers.length ? 'block' : 'hidden lg:block'}`}>
          {selectedCustomersErr && <p className="input-err-msg">{t('createChitGroup.errMsg.selectedCustomers')}</p>}
          {selectedCustomers.map(({ value, label }: any, index: number) => (
            <div className="d-flex align-center" style={{ margin: '12px 0' }} key={value}>
              <p
                style={{ margin: '0 5px 0 0' }}
                onClick={() => handleNavigateToCustomer(value)}
                className={viewChitGroup ? 'c-pointer' : ''}
                dangerouslySetInnerHTML={{ __html: `${index + 1}. ${label}` }}
              />
              {!(viewChitGroup || isChitActive) && <MdDelete className="c-pointer" onClick={() => handleRemoveUser(value)} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ChitCustomer
