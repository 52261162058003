import { Card, Space } from 'antd'
import { currencyFormatter } from '../../../utils'

interface NumberDataProps {
  t: any
  ref1?: any
  ref2?: any
  ref3?: any
  ref4?: any
  isMobile: boolean
  data: any
}

interface DataCardProps {
  t: any
  cardTitle: string
  cardData: any
}

const DataCard: React.FC<DataCardProps> = ({ t, cardTitle, cardData }) => (
  <Card size="small" title={t(cardTitle)} className="text-center">
    {cardData.map(({ label, value, currency, danger }: any, index: any) => (
      <div className="d-flex justify-between" key={index}>
        <p className="m-0 text-left" key={index}>
          {label}
        </p>
        <p className={`m-0 ${danger ? 'danger' : ''}`} key={index}>
          {currency ? currencyFormatter(value) : value || 0}
        </p>
      </div>
    ))}
  </Card>
)

const Numbers: React.FC<NumberDataProps> = ({ t, ref1, ref2, ref3, ref4, data }) => {
  const formattedData = {
    chit: [
      {
        label: t('dashboard.numbers.totalChits'),
        value: data?.chit?.ACTIVE + data?.chit?.PENDING + data?.chit?.ENDED
      },
      {
        label: t('dashboard.numbers.activeChits'),
        value: data?.chit?.ACTIVE
      },
      {
        label: t('dashboard.numbers.pendingChits'),
        value: data?.chit?.PENDING
      },
      {
        label: t('dashboard.numbers.enededChits'),
        value: data?.chit?.ENDED
      }
    ],
    customer: [
      {
        label: t('dashboard.numbers.totalCustomers'),
        value: data?.customer?.totalCount
      },
      {
        label: t('dashboard.numbers.activeCustomers'),
        value: data?.customer?.activeCount
      },
      {
        label: t('dashboard.numbers.inActiveCustomers'),
        value: data?.customer?.inactiveCount
      }
    ],
    pendingTransaction: [
      {
        label: t('dashboard.numbers.pendingTransactionCount'),
        value: data?.transaction?.pending?.count,
        danger: true
      },
      {
        label: t('dashboard.numbers.pendingTransactionAmount'),
        value: data?.transaction?.pending?.amount,
        currency: true,
        danger: true
      },
      {
        label: t('dashboard.numbers.pendingTransactionFromNoOfCustomers'),
        value: data?.transaction?.pending?.userCount,
        danger: true
      }
    ],
    receivedTransaction: [
      {
        label: t('dashboard.numbers.receivedTransactionCount'),
        value: data?.transaction?.received?.count
      },
      {
        label: t('dashboard.numbers.receivedTransactionAmount'),
        value: data?.transaction?.received?.amount,
        currency: true
      },
      {
        label: t('dashboard.numbers.receivedTransactionFromNoOfCustomers'),
        value: data?.transaction?.received?.userCount
      }
    ]
  }

  return (
    <Space direction="horizontal" size={18} wrap className="dashboard-numbers">
      <div ref={ref1}>
        <DataCard t={t} cardTitle="dashboard.numbers.chit" cardData={formattedData.chit} />
      </div>
      <div ref={ref2}>
        <DataCard t={t} cardTitle="dashboard.numbers.customer" cardData={formattedData.customer} />
      </div>
      <div ref={ref3}>
        <DataCard t={t} cardTitle="dashboard.numbers.pendingTransaction" cardData={formattedData.pendingTransaction} />
      </div>
      <div ref={ref4}>
        <DataCard t={t} cardTitle="dashboard.numbers.receivedTransaction" cardData={formattedData.receivedTransaction} />
      </div>
    </Space>
  )
}

export default Numbers
