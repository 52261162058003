import { useState, useEffect } from 'react'
import { Button, Select, Spin, Input, Space } from 'antd'
import { FaPlus } from 'react-icons/fa'
import { MdOutlineFileDownload } from 'react-icons/md'
import { FilterButtonsContainer } from '../../components/Button'
import BulkUploadModal from '../common/BulkUploadModal'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import type { RootState } from '../../redux'
import { setEnableFilter, getAutocompleteUserList, getAutocompleteChitGroupList, getAutocompleteCustomerList } from '../../redux'
import { paymentPaidStatusOptions } from '../../utils/data'
import { formatURL, formatStringEqURL, formatPayload } from '../../utils'
import constants from '../../constants'

interface FilterContainerProps {
  showFilter?: boolean
  t: any
  showSearch?: boolean
  showChitGroupOnly?: boolean
  showCustomerOnly?: boolean
  chitGroupPriority?: boolean
  hideCustomer?: boolean
  showUserOnly?: boolean
  filterType: string
  actionName?: string
  handleCreateAction?: any
  dataLength?: any
  handleDownloadAction?: any
  handleFilterValues: any
}

const FilterContainer = ({
  showFilter,
  t,
  dataLength,
  showSearch,
  filterType,
  showChitGroupOnly,
  showCustomerOnly,
  chitGroupPriority,
  hideCustomer,
  showUserOnly,
  handleDownloadAction,
  actionName,
  handleCreateAction,
  handleFilterValues,
}: FilterContainerProps) => {
  const dispatch = useAppDispatch()
  const [chitGroup, setChitGroup] = useState<string>('')
  const [installment, setInstallment] = useState<number>(0)
  const [customer, setCustomer] = useState<string>('')
  const [user, setUser] = useState<string>('')
  const [paymentPaid, setPaymentPaid] = useState<any>(paymentPaidStatusOptions[0])
  const [isReport, setIsReport] = useState<boolean>(false)
  const [showCreateActionCTA, setShowCreateActionCTA] = useState<boolean>(false)
  const [showMassUploadAction, setShowMassUploadAction] = useState<boolean>(false)
  const [showBulkUploadModal, setShowBulkUploadModal] = useState<boolean>(false)

  const { isUser } = useAppSelector((state: RootState) => state.auth)
  const { isMobile, elementSize } = useAppSelector((state: RootState) => state.common)
  const { customerAutoCompleteLoading, filteredCustomerList } = useAppSelector((state: RootState) => state.customer)
  const { chitAutoCompleteGroupLoading, filteredChitGroupList } = useAppSelector((state: RootState) => state.chitGroup)
  const { userAutoCompleteLoading, filteredUserList } = useAppSelector((state: RootState) => state.user)
  let timeout: ReturnType<typeof setTimeout> | null

  useEffect(() => {
    setIsReport(constants.filterType.REPORTS.includes(filterType))
    setShowCreateActionCTA(constants.filterType.CREATE_ACTIONS.includes(filterType))
    setShowMassUploadAction(constants.filterType.MASS_UPLOAD.includes(filterType))
  }, [filterType])

  const getDueDropDownValues = () => {
    const options: any = []
    if (chitGroup) {
      const getMonths: number = filteredChitGroupList.find(({ ID }: any) => ID === chitGroup)?.maxInstallmentNumber
      if (getMonths) {
        for (let i = 1; i <= getMonths; i++) {
          options.push({ value: i, label: i })
        }
      }
    }
    return options
  }

  const handleFilter = () => {
    dispatch(setEnableFilter(true))
    let payload = {}
    if (filterType === constants.filterType.CUSTOMER_REPORT) {
      payload = {
        customerID: customer,
        filters: formatURL(installment, '', paymentPaid.value ? paymentPaid.value : paymentPaid, chitGroup)
      }
    } else if (filterType === constants.filterType.CHIT_REPORT) {
      payload = {
        chitgroupID: chitGroup,
        filters: formatURL(installment, customer, paymentPaid.value ? paymentPaid.value : paymentPaid)
      }
    } else if (filterType === constants.filterType.CHIT_TRANSACTION) {
      payload = formatStringEqURL(installment, '', chitGroup)
    } else if (filterType === constants.filterType.CUSTOMER_TRANSACTION) {
      payload = formatStringEqURL(installment, customer, chitGroup)
    } else if (filterType === constants.filterType.ANALYTICS) {
      payload = formatPayload(chitGroup, installment, customer)
    } else if (filterType === constants.filterType.CHIT) {
      payload = chitGroup
    } else if (filterType === constants.filterType.CUSTOMER) {
      payload = customer
    }
    handleFilterValues(payload)
  }

  const handleResetValues = () => {
    setChitGroup('')
    setInstallment(0)
    setCustomer('')
    setPaymentPaid('')
    handleFilterValues('')
    setUser('')
  }

  const checkDisableSubmit = () => {
    if (filterType === constants.filterType.CHIT) {
      return chitGroup.length < 3
    } else if (filterType === constants.filterType.CUSTOMER) {
      return customer.length < 3
    } else if (filterType === constants.filterType.CUSTOMER_REPORT) {
      return !customer
    } else if (filterType === constants.filterType.CHIT_REPORT || filterType === constants.filterType.CHIT_TRANSACTION) {
      return !(chitGroup || installment)
    } else if (filterType === constants.filterType.CUSTOMER_TRANSACTION) {
      return !(customer || chitGroup || installment)
    }
  }

  const fetchChitGroupList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteChitGroupList(`name=${keyword}`))
      }, 300)
    } else {
      return []
    }
  }

  const getChitGroupOptions = () => {
    return filteredChitGroupList.map((custom: { chitName: string; ID: string }) => ({ label: custom.chitName, value: custom.ID }))
  }

  const fetchCustomerList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteCustomerList(keyword))
      }, 300)
    } else {
      return []
    }
  }

  const getCustomerOptions = () => {
    return filteredCustomerList.map((custom: { firstName: string; lastName: string; customerId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.customerId
    }))
  }

  const fetchUserList = (keyword: string) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    if (keyword.length > 2) {
      timeout = setTimeout(() => {
        dispatch(getAutocompleteUserList(keyword))
      }, 300)
    } else {
      return []
    }
  }

  const getUserOptions = () => {
    return filteredUserList.map((custom: { firstName: string; lastName: string; userId: string }) => ({
      label: `${custom.firstName} ${custom.lastName || ''}`,
      value: custom.userId
    }))
  }

  const handleShowCreateActionCTA = () => {
    return showCreateActionCTA && !isUser
  }

  const handleShowMassUploadActionCTA = () => {
    return false && showMassUploadAction && !isUser
  }

  const handleEnableBulkUploadModal = () => {
    setShowBulkUploadModal(true)
  }

  const handleConfirmUploadModal = () => {
    setShowBulkUploadModal(false)
  }

  const handleClearUploadModal = () => {
    setShowBulkUploadModal(false)
  }

  const getCreateActionName = () => {
    return actionName || t('transactions.customers.createCTA')
  }

  return (
    <div className="d-flex" style={{ flexDirection: 'column', margin: isMobile ? 0 : '10px 0' }}>
      {showFilter && (
        <div className="select-group-container">
          {!chitGroupPriority && showChitGroupOnly && showSearch && (
            <div className="select-container">
              <label className="input-label">{t('common.chitName')}</label>
              <Input
                placeholder={t('common.placeholder.selectChit')}
                allowClear={true}
                style={{ width: 290 }}
                onChange={(e: any) => {
                  setChitGroup(e.target.value)
                  e.target.value === '' && handleResetValues()
                }}
              />
            </div>
          )}
          {!chitGroupPriority && !showChitGroupOnly && !showUserOnly && showSearch && (
            <div className="select-container">
              <label className="input-label">{t('common.customerName')}</label>
              <Input
                placeholder={t('common.placeholder.selectCustomer')}
                allowClear={true}
                style={{ width: 290 }}
                onChange={(e: any) => {
                  setCustomer(e.target.value)
                  e.target.value === '' && handleResetValues()
                }}
              />
            </div>
          )}
          {!chitGroupPriority && !showChitGroupOnly && !showUserOnly && !showSearch && (
            <div className="select-container">
              <label className="input-label">{t('common.customerName')}</label>
              <Select
                allowClear={true}
                showSearch
                value={customer || null}
                placeholder={t('common.placeholder.selectCustomer')}
                filterOption={false}
                onSearch={fetchCustomerList}
                onChange={(option: any) => setCustomer(option)}
                notFoundContent={customerAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getCustomerOptions()}
                className="select-width"
              />
            </div>
          )}
          {!showCustomerOnly && !showUserOnly && !showSearch && (
            <div className="select-container">
              <label className="input-label">{t('common.chitName')}</label>
              <Select
                value={chitGroup || null}
                allowClear={true}
                showSearch
                filterOption={false}
                onSearch={fetchChitGroupList}
                placeholder={t('common.placeholder.selectChit')}
                onChange={(option: any) => setChitGroup(option)}
                notFoundContent={chitAutoCompleteGroupLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getChitGroupOptions()}
                className="select-width"
              />
            </div>
          )}
          {!showChitGroupOnly && !showCustomerOnly && !showUserOnly && (
            <div className="select-container">
              <label className="input-label">{t('common.completedInstallment')}</label>
              <Select
                disabled={!chitGroup}
                allowClear={true}
                value={installment || null}
                showSearch
                filterOption={false}
                placeholder={t('common.placeholder.selectInstallment')}
                options={getDueDropDownValues()}
                onChange={(option: any) => setInstallment(option)}
                className="select-width select-width-installment"
              />
            </div>
          )}
          {!hideCustomer && chitGroupPriority && !showChitGroupOnly && (
            <div className="select-container">
              <label className="input-label">{t('common.customerName')}</label>
              <Select
                allowClear={true}
                showSearch
                value={customer || null}
                placeholder={t('common.placeholder.selectCustomer')}
                filterOption={false}
                onSearch={fetchCustomerList}
                onChange={(option: any) => setCustomer(option)}
                notFoundContent={customerAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getCustomerOptions()}
                className="select-width"
              />
            </div>
          )}
          {showUserOnly && (
            <div className="select-container">
              <label className="input-label">{t('common.userName')}</label>
              <Select
                allowClear={true}
                showSearch
                value={user || null}
                placeholder={t('common.placeholder.selectUser')}
                filterOption={false}
                onSearch={fetchUserList}
                onChange={(option: any) => setUser(option)}
                notFoundContent={userAutoCompleteLoading ? <Spin size="small" className="search-loading" /> : <span className="search-loading">{t('common.noData')}</span>}
                options={getUserOptions()}
                className="select-width"
              />
            </div>
          )}
          {isReport && (
            <div className="select-container payment-paid-status-select-container">
              <label className="input-label">{t('common.paymentPaid')}</label>
              <Select
                showSearch
                allowClear={true}
                value={paymentPaid ? paymentPaid : null}
                placeholder={t('common.placeholder.selectPaymentPaid')}
                onChange={(option: any) => setPaymentPaid(option)}
                options={paymentPaidStatusOptions}
                className="select-width"
              />
            </div>
          )}
        </div>
      )}
      <div className={`d-flex ${showFilter ? 'justify-between' : 'justify-end'}`} style={{ width: '100%', margin: '15px 0' }}>
        {showFilter && <FilterButtonsContainer t={t} isMobile={isMobile} disabled={checkDisableSubmit()} handleFilter={handleFilter} handleReset={handleResetValues} />}
        {isReport && (
          <Button size={elementSize} disabled={!dataLength} onClick={handleDownloadAction} className="d-flex align-center" style={{ gap: '4px' }}>
            <MdOutlineFileDownload style={{ width: '18px', height: '18px' }} />
            {t('common.download')}
          </Button>
        )}
        {(handleShowMassUploadActionCTA() || handleShowCreateActionCTA()) && <Space>
          {handleShowMassUploadActionCTA() && (
            <Button size={elementSize} onClick={handleEnableBulkUploadModal}>
              {t('common.massUpload')}
            </Button>
          )}
          {handleShowCreateActionCTA() && (
            <Button size={elementSize} type="primary" onClick={handleCreateAction} className='d-flex align-center'>
              {isMobile ? <FaPlus /> : getCreateActionName()}
            </Button>
          )}
        </Space>}
      </div>
      {showBulkUploadModal &&
        <BulkUploadModal
          visibleModal={showBulkUploadModal}
          confirmModal={handleConfirmUploadModal}
          closeModal={handleClearUploadModal}
        />
      }
    </div>
  )
}

export default FilterContainer
