import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout } from 'antd'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import SideBarMenu from '../components/SideBarMenu'
import AppHeader from '../components/AppHeader'
import config from '../config'
import { useAppSelector, useAppDispatch } from '../redux/hooks'
import { setToken, setUserData, getRefreshToken } from '../redux'
import SimpleModal from '../components/common/SimpleModal'
import { handleLogout } from '../utils'

const { Content, Footer } = Layout

const ProtectedRoute = ({ component: Component, allowedRoles = [] }: any) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const token: any = window.localStorage.getItem('jwt')
  const decoded: any = jwtDecode<JwtPayload>(token)

  const { viewFullMenu, isMobile } = useAppSelector((state) => state.common)
  const isAuthorized: boolean = allowedRoles.includes(decoded?.Role)

  const [timestamp, setTimestamp] = useState(Date.now())
  const [refreshModal, setRefreshModal] = useState<any>(false)
  const [declinedRefreshModal, setDeclinedRefreshModal] = useState<any>(false)
  const [refreshModalContent] = useState<any>({
    title: t('refreshTokenModal.title'),
    desc: t('refreshTokenModal.description')
  })

  useEffect(() => {
    dispatch(setToken(token))
    dispatch(setUserData(decoded))
  }, [])

  useEffect(() => {
    const expireDate = new Date(decoded.exp * 1000)
    if (timestamp > expireDate.getTime()) {
      handleLogout()
    }

    const intervalId = setInterval(() => {
      setTimestamp(Date.now())
    }, 1000)

    expireDate.setMinutes(expireDate.getMinutes() - 5)
    const res = timestamp > expireDate.getTime()
    if (!declinedRefreshModal && res) {
      setRefreshModal(true)
    }
    return () => clearInterval(intervalId)
  }, [timestamp])

  const handleConfirmModal = () => {
    dispatch(getRefreshToken({}))
    setDeclinedRefreshModal(true)
    setRefreshModal(false)
  }

  if (!token) {
    return <Navigate to={config.pages.login} />
  }

  if (!isAuthorized) {
    return <Navigate to={config.pages.unAuthorized} replace />
  }

  const hideLayout = () => {
    return isMobile ? viewFullMenu : true
  }

  return (
    <Layout style={{ overflow: 'hidden', height: '100vh' }}>
      <SideBarMenu />
      {hideLayout() && (
        <Layout className="site-layout">
          <AppHeader />
          <Content className="main-content">
            <Component />
          </Content>
          <Footer style={{ textAlign: 'center', padding: '14px', background: '#eee' }}>People Chit ©2024</Footer>
        </Layout>
      )}
      {refreshModal && (
        <SimpleModal
          visibleModal={refreshModal}
          data={refreshModalContent}
          confirmModal={handleConfirmModal}
          closeModal={() => {
            setDeclinedRefreshModal(true)
            setRefreshModal(false)
          }}
        />
      )}
    </Layout>
  )
}

export default ProtectedRoute
