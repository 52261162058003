import { BiReset } from 'react-icons/bi'
import { MdOutlineFilterAlt } from 'react-icons/md'
import { Button } from 'antd'
import { useAppSelector } from '../../redux/hooks'
import type { RootState } from '../../redux'

interface ButtonProps {
  t?: any
  name?: string
  show?: boolean
  isMobile?: boolean
  disabled?: any
  handleFilter?: any
  handleReset?: any
}

export const FilterButtonsContainer = ({ t, isMobile, disabled, handleFilter, handleReset }: ButtonProps) => {
  const { elementSize } = useAppSelector((state: RootState) => state.common)

  return (
    <div className="d-flex">
      <Button size={elementSize} type="primary" disabled={disabled} onClick={handleFilter} style={{ marginRight: '20px' }}>
        {isMobile ? <MdOutlineFilterAlt /> : t('common.filter')}
      </Button>
      <Button size={elementSize} disabled={disabled} onClick={handleReset}>
        {isMobile ? <BiReset /> : t('common.reset')}
      </Button>
    </div>
  )
}
