import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Spin, Tour } from 'antd'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import { getDashboardStatusCount, getDashboardPaymentGraph, updateOnboardingTourData } from '../../redux'
import Numbers from '../../components/dashboard/Numbers'
import UpcomingChits from '../../components/dashboard/UpcomingChits'
import CollectionDetailsByAmount from '../../components/dashboard/CollectionDetails/amount'
import CollectionDetailsByCount from '../../components/dashboard/CollectionDetails/count'
import userConfigConstants, { OnboardingTourKeys } from '../../constants/userConfig'
import type { TourProps } from 'antd'
import type { RootState } from '../../redux'

const Dashboard: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isMobile } = useAppSelector((state) => state.common)
  const { onboardingTourData } = useAppSelector((state: RootState) => state.userConfig)
  const { isLoading, dashboardStatusCount, paymentTransactionGraphData } = useAppSelector((state: RootState) => state.analytics)

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  const ref6 = useRef(null)
  const [tourStatus, setTourStatus] = useState(false)

  useEffect(() => {
    const dashboardKey: OnboardingTourKeys = userConfigConstants.onboarding.DASHBOARD
    setTourStatus(onboardingTourData[dashboardKey])
  }, [onboardingTourData])

  const steps: TourProps['steps'] = [
    {
      title: t('tour.dashboard.chitStatsTitle'),
      description: t('tour.dashboard.chitStatsDesc'),
      target: () => ref1.current
    },
    {
      title: t('tour.dashboard.customerStatsTitle'),
      description: t('tour.dashboard.customerStatsDesc'),
      target: () => ref2.current
    },
    {
      title: t('tour.dashboard.pendingTransStatsTitle'),
      description: t('tour.dashboard.pendingTransStatsDesc'),
      target: () => ref3.current
    },
    {
      title: t('tour.dashboard.receivedTransStatsTitle'),
      description: t('tour.dashboard.receivedTransStatsDesc'),
      target: () => ref4.current
    },
    {
      title: t('tour.dashboard.collectionDetailsAmountTitle'),
      description: t('tour.dashboard.collectionDetailsAmountDesc'),
      target: () => ref5.current
    },
    {
      title: t('tour.dashboard.collectionDetailsCountTitle'),
      description: t('tour.dashboard.collectionDetailsCountDesc'),
      target: () => ref6.current
    }
  ]

  useEffect(() => {
    dispatch(getDashboardStatusCount({}))
    dispatch(getDashboardPaymentGraph({}))
  }, [dispatch])

  const formatDashboardStatusCount = () => {
    if (Object.keys(dashboardStatusCount).length) {
      let chitStatusCount = {}
      if (dashboardStatusCount.chit.length) {
        chitStatusCount = dashboardStatusCount.chit?.reduce((acc: any, item: any) => {
          acc[item.status] = item.count
          return acc
        }, {})
      }
      return {
        chit: chitStatusCount,
        customer: dashboardStatusCount.customer,
        transaction: dashboardStatusCount.transaction
      }
    }
    return {}
  }

  const formatGraphAmountData = () => {
    if (paymentTransactionGraphData.amount?.length) {
      const arr: any = []
      paymentTransactionGraphData.amount.forEach(({ period, amount, unpaidAmount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: parseInt(amount),
          type: 'Received'
        })
        arr.push({
          label: shortHandPeriod,
          value: parseInt(unpaidAmount),
          type: 'Not Received'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.amount
  }

  const formatGraphCountData = () => {
    if (paymentTransactionGraphData.count?.length) {
      const arr: any = []
      paymentTransactionGraphData.count.forEach(({ period, count, unpaidCount }: any) => {
        const splittedPeriod = period.split('-')
        const shortHandPeriod = `${splittedPeriod[0].slice(0, 3)} - ${splittedPeriod[1].slice(2)}`
        arr.push({
          label: shortHandPeriod,
          value: count || 0,
          type: 'Paid'
        })
        arr.push({
          label: shortHandPeriod,
          value: unpaidCount || 0,
          type: 'Unpaid'
        })
      })
      return arr
    }

    return paymentTransactionGraphData.count
  }

  const handleCloseOnboardingTour = () => {
    setTourStatus(false)
    const payload = {
      value: false,
      name: userConfigConstants.onboarding.DASHBOARD
    }
    dispatch(updateOnboardingTourData(payload))
  }

  return (
    <div className="dashboard">
      {isLoading ? (
        <Spin className="page-loading" style={{ height: '40vh' }} />
      ) : (
        <>
          <div>
            <Numbers t={t} ref1={ref1} ref2={ref2} ref3={ref3} ref4={ref4} isMobile={isMobile} data={formatDashboardStatusCount()} />
          </div>
          <div className="dashboard-report__container">
            {formatGraphAmountData() && (
              <div ref={ref5} className="dashboard-report">
                <CollectionDetailsByAmount t={t} data={formatGraphAmountData()} />
              </div>
            )}
            {formatGraphCountData() && (
              <div ref={ref6} className="dashboard-report">
                <CollectionDetailsByCount t={t} data={formatGraphCountData()} />
              </div>
            )}
          </div>
          <div>
            <UpcomingChits />
          </div>
          <Tour open={tourStatus} onClose={handleCloseOnboardingTour} steps={steps} />
        </>
      )}
    </div>
  )
}

export default Dashboard
